<template>
  <div id="app" class="flexcontainer">
    <header>
      <nav id="nav"
           class="
        container
        shadow-md
        px-0
        py-0
        mx-auto
        md:flex md:justify-between md:items-center
      "
      >
        <div class="flex items-center justify-between">
          <router-link
              to="/"
              class="
            text-xl
            font-bold
            text-gray-100
            md:text-2xl
            hover:text-indigo-400
            imglink
          "
          ><img src="@/assets/logo.svg" alt="Logo" class="h-8">
          </router-link>
          <!-- Mobile menu button -->
          <div @click="toggleMenu" class="flex md:hidden">
            <button
                type="button"
                class="
              text-gray-100
              hover:text-gray-400
              focus:outline-none focus:text-gray-400
            "
            >
              <svg viewBox="0 0 24 24" class="w-6 h-6 fill-current">
                <path
                    fill-rule="evenodd"
                    d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                ></path>
              </svg>
            </button>
          </div>
        </div>

        <!-- Mobile Menu open: "block", Menu closed: "hidden" -->
        <ul
            :class="showMainNavMenu ? 'flex' : 'hidden'"
            class="
          flex-col
          mt-8
          space-y-4
          md:flex md:space-y-0 md:flex-row md:items-center md:space-x-10 md:mt-0
        "
        >
          <li>
            <router-link to="/" @click.native="hideMenu" class="text-highlight-900 hover:text-highlight-800">Home
            </router-link>
          </li>
          <li v-for="(item, index) in $router.getRoutes().filter((route)=> route.props.default.displayInMainNavigation===true)"
              v-bind:key="index">
            <router-link :to=item.path @click.native="hideMenu"
                         class="text-highlight-900 hover:text-highlight-700">{{ item.name }}
            </router-link>
          </li>
        </ul>
      </nav>
    </header>
    <main class="container mx-auto shadow-md">
      <router-view class="py-1"/>
    </main>
    <footer>
      © 2022 R2H2, all rights reserved
    </footer>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'Stats',
  computed: {
    ...mapState([
      'showMainNavMenu'
    ])
  },
  methods: {
    toggleMenu() {
      this.$store.commit('toggleMainNavMenu');
    },
    hideMenu() {
      this.$store.commit('hideMainNavMenu');
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 1rem;
}

#nav a {
  font-weight: bold;
  text-decoration: unset;
}

#nav a.imglink {
  border: unset;
}

#nav a.router-link-exact-active {
  /*@apply text-highlight-900;*/
  @apply border-b-4;
  /*@apply border-highlight-900;*/
}

html, body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

.flexcontainer {
  height: 100%;
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto 0;
}

main {
  margin: 0 auto 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
