import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Imprint from '../views/impressum'
import RechtlHinweise from '../views/rechtliche_hinweise'
import Error404 from '../views/err_404'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        props: {displayInMainNavigation: false} // -dr-: Eigene Property hinzugefügt
    },
    {
        path: '/impressum',
        name: 'Impressum',
        component: Imprint,
        props: {displayInMainNavigation: true}
    },
    {
        path: '/rechtliche_hinweise',
        name: 'Rechtliche Hinweise',
        component: RechtlHinweise,
        props: {displayInMainNavigation: true}
    },
    // {
    //     path: '/test',
    //     name: 'test',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "test_vue" */ '../views/test_home.vue'),
    //     props: {displayInMainNavigation: false}
    // }
    {
        path: '*',
        name: 'Page not found',
        component: Error404,
        props: {displayInMainNavigation: false}
    },

]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router
