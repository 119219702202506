import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        showMainNavMenu: false,
        title: '-dr- Title',
        links: [
            'https://r2h2.net',
            'https://google.de',
            'http://youtube.com'
        ]
    },
    getters: {
        countLinks: state => {
            return state.links.length
        }
    },
    mutations: {
        toggleMainNavMenu(state) {
            state.showMainNavMenu = !state.showMainNavMenu;
            // console.log("showMainNavMenu=" + state.showMainNavMenu);
        },
        hideMainNavMenu(state) {
            state.showMainNavMenu = false;
            // console.log("hideMainNavMenu=" + state.showMainNavMenu);
        }
    },
    actions: {},
    modules: {}
})
